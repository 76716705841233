const document = {
  namespaced: true,
  state: [],
  actions: {
    setUser({ commit }, data) {
      commit("setUser", data);
    },
    setSections({ commit }, data) {
      commit("setSections", data);
    },
    setDocuments({ commit }, data) {
      commit("setDocuments", data);
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setSections(state, data) {
      state.sections = data;
    },
    setDocuments(state, data) {
      state.documents = data;
    },
  },
};

export default document;
