import store from "../store/index"

function checkAuth(router) {
  router.beforeEach(async (to, from, next) => {
    const publicPages = ['/'];
    const authRequired = !publicPages.includes(to.path);
    if (authRequired && !store.state.auth.status) {
      next('/')
    } else {
      next()
    }
  });
}

export default checkAuth;