const checklist = {
  namespaced: true,
  state: [],
  actions: {
    setUser({ commit }, data) {
      commit("setUser", data);
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
  },
};

export default checklist;
