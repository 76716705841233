import { GENERAL } from "../DEBUG";

let DEBUG = GENERAL;

let url = ""; // "https://www.transfluid-admin.de";
let loginPage = `${url}/login/#/`;
let checklistPage = `${url}/checklist/#/`;
let logisticPage = `${url}/logistic/#/`;
let adminPage = `${url}/admin/#/`;
let documentPage = `${url}/document/#/`;

/**
 * Könnte man zentralisieren, machen wir aber nicht damit die Datei immer gebaut werden kann
 * auch wenn auf dem Server keine DEBUG.js definiert ist.
 *
 * Schaut einfach nur ob es aktuell ein gebautes "Produkt" ist oder eine Entwicklungsumgebung.
 */
if (process.env.NODE_ENV !== "development") {
  DEBUG = false;
}

if (DEBUG) {
  loginPage = "http://localhost:8080/login#/";
  checklistPage = "http://localhost:8081/checklist#/";
  logisticPage = "http://localhost:8082/logistic#/";
  adminPage = "http://localhost:8083/admin#/";
  documentPage = "http://localhost:8085/document#/";
}

export { loginPage, checklistPage, logisticPage, adminPage, documentPage };
