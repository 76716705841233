const GENERAL = true;
const ADMIN = false;
const CHECKLIST = false;
const LOGISTIC = false;

export {
  GENERAL,
  ADMIN,
  CHECKLIST as CHECKLIST_DEBUG /* In dem File checklist/src/constants.js gibt es bereits eine Variable mit dem Namen CHECKLIST - deswegen hier der spezielle Name. */,
  LOGISTIC,
};
