<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import userValidation from "../../a-general/functions/user/user_validation"
export default {
  name: 'App',
  async mounted() {
    await this.$router.isReady()
    if (this.$route.query.logout) {
      this.$store.dispatch("auth/logout")
      return;
    }
    await userValidation(this.$store, this.$router, this.$route)
  },
}
</script>

