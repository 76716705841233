import axios from "axios";

async function post(url, body, config = {}) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (config.headers == undefined) {
    config.headers = {
      authtoken: "",
    };
  }
  if (user && !config.headers.authtoken) {
    config.headers = { authtoken: user["authtoken"] };
  }
  try {
    const response = await axios.post(`/api/${url}`, body, config);
    return response;
  } catch (err) {
    console.log(err.response);
  }
}

export default post;
