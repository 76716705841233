<template>
  <div class="horizontal">
    <div class="vertical"><GridLoader color="#B40000" size="18px" /></div>
  </div>
</template>

<script>
import GridLoader from "vue-spinner/src/GridLoader.vue";
export default {
  name: "LoadingSpinner",
  components: { GridLoader },
};
</script>

<style>
.horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vertical {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
</style>