import { createStore } from "vuex";
import auth from "./auth.module";
import checklist from "./checklist.module";
import logistic from "./logistic.module";
import admin from "./admin.module";
import document from "./document.module";
export default createStore({
  modules: {
    auth,
    checklist,
    logistic,
    admin,
    document,
  },
});
