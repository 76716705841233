async function userValidation(store, router, route) {
  await router.isReady()
  let user = {}
  user.authtoken = route.query.authtoken
  if (!user.authtoken) {
    user = JSON.parse(localStorage.getItem('user'));
  }
  if (!user) {
    localStorage.clear()
    return { status: false, message: "No Auth Token" }
  }
  if (!user.authtoken) {
    localStorage.clear()
    return { status: false, message: "No Auth Token" }
  }

  let token = user.authtoken
  const response = await store.dispatch("auth/validation", token)
  if (response.status) {
    router.push("/dashboard")
  }
  return response
}

export default userValidation