const logistic = {
  namespaced: true,
  state: [],
  actions: {
    setSendData({ commit }, sendData) {
      commit("setSendData", sendData);
    },
    setTransportData({ commit }, setTransportData) {
      commit("setTransportData", setTransportData);
    },
  },
  mutations: {
    setSendData(state, sendData) {
      state.sendData = sendData;
    },
    setTransportData(state, setTransportData) {
      state.transportData = setTransportData;
    },
  },
};

export default logistic;
