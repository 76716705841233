<template>
  <div class="horizontal">
    <div class="vertical">
      <CustomCards :list="applications" />
    </div>
    <div class="absolutefixed">
      <img
        src="../../../a-general/assets/logo.png"
        alt="transfluid Logo"
        width="300"
      />
    </div>
    <div class="logout">
      <q-btn icon="logout" label="Logout" @click="logout"></q-btn>
    </div>
  </div>
</template>

<script>
import {
  checklistPage,
  logisticPage,
  adminPage,
  loginPage,
  documentPage,
} from "../../../a-general/constants";
import CustomCards from "../../../a-general/components/custom_cards.vue";
export default {
  components: { CustomCards },
  name: "DashboardOverview",
  mounted() {
    document.title = "Login - Dashboard";
  },
  methods: {
    logout() {
      window.location.href = `${loginPage}?logout=true`;
    },
  },
  data() {
    return {
      applications: [
        {
          name: "AdminPanelTfApp",
          description: "",
          auth_required: true,
          icon: "supervised_user_circle",
          label: "Admin - App",
          rights: this.$store.state.auth.user.rights.admin.user_management,
          url: adminPage,
        },
        {
          name: "Checklisten",
          description: "",
          auth_required: true,
          icon: "list",
          label: "Checklisten",
          rights: true,
          url: checklistPage,
        },
        {
          name: "Logistik",
          description: "",
          auth_required: true,
          icon: "local_shipping",
          rights: this.$store.state.auth.user.rights.logistic.edit,
          label: "Logistik",
          url: logisticPage,
        },
        {
          name: "Dokumenten-Manager",
          description: "",
          auth_required: true,
          icon: "description",
          rights:
            this.$store.state.auth.user.rights.develop ||
            this.$store.state.auth.user.rights.admin.document,
          label: "Dokumente",
          url: documentPage,
        },
      ],
    };
  },
};
</script>

<style scoped>
.absolutefixed {
  position: fixed;
  top: 40px;
  text-align: center;
}

.logout {
  position: fixed;
  top: 40px;
  right: 40px;
}
</style>
