
import AuthService from "../functions/user/auth.service"

const auth = {
  namespaced: true,
  state: {},
  actions: {
    async login({ commit }, user) {
      const auth = await AuthService.login(user)
      if (auth.authtoken) {
        commit('loginSuccess', auth);
        return auth
      }
      commit('loginFailure');
      return false
    },
    async validation({commit}, token){
      const user = await AuthService.validation(token)
      if(user.status){
        commit('loginSuccess', user);
      }
      return user
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    setState({commit}, user){
        commit('loginSuccess', user)
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status = false;
      state.user = null;
    },
    logout(state) {
      state.status = false;
      state.user = null;
    }
  }
}


export default auth