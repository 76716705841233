

const admin = {
    namespaced: true,
    state: [],
    actions: {
        setUser({ commit }, data) {
            commit('setUser', data)
        },
        setSections({ commit }, data) {
            commit('setSections', data)
        },
        setUser({ commit }, data) {
            commit('setUser', data)
        },
        setKeywords({ commit }, data) {
            commit('setKeywords', data)
        },
        setNews({ commit }, data) {
            commit('setNews', data)
        }, setNewsArchive({ commit }, data) {
            commit('setNewsArchive', data)
        },
        setSurvey({ commit }, data) {
            commit('setSurvey', data)
        }, setSurveyArchive({ commit }, data) {
            commit('setSurveyArchive', data)
        },
    },
    mutations: {
        setUser(state, data) {
            state.user = data;
        },
        setSections(state, data) {
            state.sections = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        setKeywords(state, data) {
            state.keywords = data;
        },
        setNews(state, data) {
            state.news = data;
        },
        setNewsArchive(state, data) {
            state.newsArchive = data;
        },
        setSurvey(state, data) {
            state.survey = data;
        },
        setSurveyArchive(state, data) {
            state.surveyArchive = data;
        },
    }
}


export default admin