import post from "../request/post"

class AuthService {

  async validation(token) {
    let response = await post("/validation", {}, { headers: { 'authtoken': token } })
    response = response.data
    localStorage.clear()
    if (response.status) {
      localStorage.setItem('user', JSON.stringify(response));
    }
    return response
  }
  async login(user) {
    let response = await post("/login", user)
    response = response.data
    localStorage.clear()
    if (response.authtoken) {
      localStorage.setItem('user', JSON.stringify(response));
    }
    return response
  }
  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();