<template>
  <div class="row">
    <div
      @click="switchSite(item.url, item.auth_required)"
      style="padding: 16px; border-radius: 20px; margin: 16px"
      class="card"
      v-for="item in list"
      v-bind:key="item.name"
      v-show="!item.auth_required || item.rights"
    >
      <q-icon :name="item.icon" size="128px"></q-icon>
      <div style="text-align: center; font-size: 24px">{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCard",
  props: ["list", "router"],
  methods: {
    switchSite(url, auth) {
      if (this.router) {
        this.$router.push(url);
        return;
      }
      if (auth) {
        window.location.href = `${url}?authtoken=${this.$store.state.auth.user["authtoken"]}`;
      }
      this.$router.push(url);
    },
  },
};
</script>

<style scopep>
.card:hover {
  background-color: #b40000;
  color: white;
  cursor: pointer;
}
</style>
